<script lang="ts">
import { defineComponent } from 'vue'
import AgreementList from './AgreementList.vue';
import { AgreementPopup } from './popups';
import { getAgreementsConfig } from '../api';
import { customFilterAgreement } from '../model';

const mapPopup = {
    'docs_popup': 'AgreementPopup'
};

export default defineComponent({
    components: { AgreementList, AgreementPopup },
    props: {
        additionalDocumentParams: {
            type: Object,
            default: () => ({})
        },
        isLoadingOutside: {
            type: Boolean,
            default: false
        },
        positionAgreements: {
            type: String,
            default: 'center'
        }
    },
    data() {
        return {
            isLoading: false,
            agreements: []
        }
    },
    computed: {
        popupName() {
            return mapPopup[this.$store.getters['agreementsModel/popupSettings'].code];
        },
        isValidateAgreements() {
            return this.$store.getters['agreementsModel/isValidateAgreements'];
        }
    },
    watch: {
        '$store.state.agreementsModel.agreements': {
            handler(agreements) {
                this.agreements = customFilterAgreement(agreements);
            },
            deep: true
        },
        '$store.state.agreementsModel.agreementsMap': {
            handler() {
                this.agreements = customFilterAgreement(this.$store.getters['agreementsModel/agreements']);
            },
            deep: true
        },
        isValidateAgreements: {
            handler(isValidateAgreements) {
                this.$emit('validateAgreements', isValidateAgreements && !this.isLoading);
            }
        },
        additionalDocumentParams: {
            handler(additionalDocumentParams) {
                this.$store.commit('agreementsModel/set', {
                    additionalDocumentParams
                })
            },
            deep: true
        },
        isLoadingOutside: {
            handler(isLoadingOutside) {
                this.$emit('validateAgreements', this.isValidateAgreements && !isLoadingOutside);
                this.isLoading = isLoadingOutside;
            }
        }
    },
    async created() {
        this.isLoading = true;
        const agreements = await getAgreementsConfig();
        this.$store.dispatch('agreementsModel/updateAgreements', {
            agreements
        });
        this.isLoading = false;
    },
    beforeDestroy() {
        this.$store.dispatch('agreementsModel/clearState');
    }
})
</script>

<template>
    <div class="agreements" :class="{
        'agreements--loading': isLoading,
         'agreements--left': positionAgreements === 'left',
         'agreements--center': positionAgreements === 'center'
    }">
        <div v-if="isLoading" class="loader">
            <div class="loader__icon banter-loader">
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
                <div class="banter-loader__box"></div>
            </div>
            <div class="loader__text">
                Загружаем контент...
            </div>
        </div>
        <AgreementList v-else :agreements="agreements" />
        <component :is="popupName" />
    </div>
</template>

<style scoped lang="scss">
    .agreements {
        display: flex;

        &--left {
            justify-content: flex-start !important;
        }

        &--center {
            justify-content: center !important;
        }

        &--loading {
            justify-content: center !important;
        }
    }
</style>
