<script lang="ts">
import { defineComponent } from 'vue'
import {
    CheckboxParent,
    Checkbox,
    Title,
    Radio
} from './agreementTypes';
import Links from './Links.vue';
import { mapAgreementTypes, AgreementsHelper, customEventAgreement } from '../model';

export default defineComponent({
    name: 'Agreement',
    components: { Links, Checkbox, Title, Radio, CheckboxParent },
    props: {
        agreement: {
            type: Object,
            default: () => ({})
        },
        isChildren: {
            type: Boolean,
            default: false
        },
        filter: {} as any
    },
    data() {
        return {
            agreementLocal: {} as {
                code: '',
                defaultSelected: false,
                type: 'checkbox',
                group: '',
                text: '',
                req: false,
                value: '',
                isChildrenOpen: true,
                children: [],
                links: []
            }
        }
    },
    computed: {
        activeLists() {
            return this.$store.getters['agreementsModel/listSettings'].activeLists;
        },
        agreements() {
            return this.$store.getters['agreementsModel/agreements'];
        },
        isChildrenShow() {
            return this.activeLists[this.agreementLocal.code] || this.activeLists[this.agreementLocal.code] == null && this.agreementLocal.isChildrenOpen;
        }
    },
    watch: {
        agreement: {
            handler(agreement) {
                this.agreementLocal = agreement;
            },
            immediate: true
        },
        filter: {
            handler(filter) {
                if (Object.keys(filter).length === 0) {
                    return;
                }
                this.agreementLocal = AgreementsHelper.findAgreementByFilter(this.agreements, filter) ?? {
                    code: '',
                    defaultSelected: false,
                    type: 'checkbox',
                    group: '',
                    text: '',
                    req: false,
                    value: '',
                    isChildrenOpen: true,
                    children: [],
                    links: []
                };
            },
            deep: true
        }
    },
    methods: {
        resolveComponentName(type) {
            return mapAgreementTypes[type];
        },
        customEventAgreement
    }
})
</script>

<template>
    <component
        v-if="agreementLocal.code !== ''"
        class="agreement"
        :is="resolveComponentName(agreementLocal.type)"
        :agreement="agreementLocal"
        :isChildren="isChildren"
        @change="customEventAgreement">
        <template #links>
            <Links :links="agreementLocal.links" :text="agreementLocal.text"  :agreement="agreementLocal"/>
        </template>
        <template v-if="agreementLocal?.children?.length > 0" #children>
            <transition name="slide">
                <div v-show="isChildrenShow" class="agreement__children">
                    <Agreement
                        v-for="(agreementChildren) in (agreementLocal?.children ?? [])"
                        :key="agreementChildren.text"
                        :agreement="agreementChildren"
                        :isChildren="true"
                        @change="customEventAgreement">
                    </Agreement>
                </div>
            </transition>
        </template>
    </component>
</template>

<style scoped lang="scss">
.agreement {
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
    &__children {
        margin-top: 12px;
    }
}
</style>
