<script lang="ts">
import { defineComponent } from 'vue'
import Agreement from './Agreement.vue';

export default defineComponent({
    components: { Agreement },
    props: {
        agreements: {
            type: Array,
            default: () => []
        }
    }
})
</script>

<template>
    <div class="agreements-list--wrapper">
        <div class="agreements-list">
            <Agreement
                v-for="(agreement) in agreements"
                :key="agreement.text"
                :agreement="agreement"/>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .agreements-list {
        display: flex;
        flex-direction: column;
        &--wrapper {
            display: flex;
            justify-content: center;
        }
    }
</style>
