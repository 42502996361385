<template>
    <div class="popup" :class="{
        'popup--back': back
    }">
        <div class="popup__box">
            <div class="popup__content">
                <div class="popup__content-wrapper">
                    <span class="popup__content-close" @click="close()"></span>
                    <slot/>
                </div>
                <div v-if="back"
                     @click="close"
                     class="popup__back">
                    {{ back }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        name: 'Popup',
        props: {
            back: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            isPopup: true
        }),
        methods: {
            close() {
                this.$store.dispatch('agreementsModel/updatePopupSettings', {
                    code: ''
                })
            }
        }
    })
</script>

<style lang="scss" scoped>
    @import "~@/app/style/helpers/variables";

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background: rgba(#000, .45);
        z-index: 6;
        box-sizing: border-box;
        overflow: auto;
        align-items: flex-start;
        justify-content: center;

        &__agreement {
            margin-top: 30px;
            font-size: 12px;

            .checkbox__element input + span {
                width: 20px;
                height: 20px;

                &::before {
                    left: 7px;
                    top: 3px;
                    width: 3px;
                    height: 9px;
                }
            }

            &-existed {
                text-align: center;
                @media(max-width: 428px) {
                    text-align: left;
                }
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            margin-top: 40px;
        }

        &__description {
            font-size: 14px;
            line-height: 1.57;

            & > p:not(:last-child) {
                margin-bottom: 10px;
            }

            a {
                color: #18a4d2;
                font-weight: 500;
                text-decoration: none;
                font-size: 1.12em;
            }

            &:not(:last-child) {
                margin-bottom: 25px;
            }

            &-existed {
                text-align: center;
                font-weight: 400;
                color: #73869d;
                @media(max-width: 428px) {
                    text-align: left;
                    margin-right: -1px;
                }

                & > span {
                    font-weight: 500;
                    color: #0b161f
                }
            }
        }

        &__text {
            font-size: 16px;
            line-height: 1.63;
            margin-bottom: 46px;
            text-align: center;
        }

        &__close {
            display: block;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            z-index: 1;
        }

        &__error {
            display: block;
            padding: 18px 14px;
            border-radius: 8px;
            border: solid 1px;
            color: #e64e73;
            font-size: 14px;
            line-height: 1.4;
        }

        &__box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
            width: 100%;
            box-sizing: border-box;
            align-items: center;
            min-height: 100%;
        }

        &__content-wrapper {
            padding: 40px;
        }

        &__content {
            max-width: none;
            width: 100%;
            height: 100vh;
            background: #FFF;
            box-sizing: border-box;
            border-radius: 0;
            position: relative;
            box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);

            &-close {
                position: absolute;
                top: 18px;
                right: 18px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                display: block;
                background-image: url('~@/shared/assets/close.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: 2;
            }
        }

        .actions {
            .button {
                width: auto;
            }

            & > * {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                & > * {
                    margin: 6px 10px;
                    flex-grow: 1;
                }
            }
        }

        &__title {
            font-size: 26px;
            font-weight: 600;
            line-height: 1.23;

            &-center {
                text-align: center;
            }

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &-padding-big {
                &:not(:last-child) {
                    margin-bottom: 50px;
                }
            }

            &-existed {
                font-size: 24px;
                text-align: center;
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                @media(max-width: 428px) {
                    text-align: left;
                }
            }
        }

        &__form {
            position: relative;

            & > * {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            & > button[type="submit"] {
                margin-top: 30px;
                display: block;
                width: 100%;
            }
        }

        &__body {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &-existed {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }

        &__justify {
            display: flex;
            justify-content: space-between;
        }

        &__footer {
            font-size: 14px;

            @media(min-width: $phone-min) {
                margin-bottom: -20px;
            }

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &-existed {
                text-align: center;
                margin-bottom: 10px;
                @media(max-width: 428px) {
                    text-align: left;
                }
            }

            .link {
                font-weight: 600;
                border-style: dotted;
            }
        }

        .button {
            width: 100%;
        }


        &__back {
            font-weight: 500;
            padding: 30px 40px 30px 76px;
            border-top: 1px solid #DADFE5;
            background: url('~@/shared/assets/back.svg') no-repeat center left 40px;
            background-size: 16px 16px;
            font-size: 16px;
            line-height: 1.5;
            cursor: pointer;
            color: #18A4D2;

            @media(max-width:$phone) {
                font-size: 12px;
                padding: 24px 20px 24px 42px;
                background-size: 11px 11px;
                background-position: center left 20px;
            }
        }
    }

    .popup.popup--back {
        @media(max-width: $phone) {
            .popup {
                &__box {
                    padding: 0;
                }

                &__content-wrapper {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }

                &__content {
                    max-width: initial!important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }
</style>
